import "./Header.css";
import tomorrowsVintageSVG from "../../assets/tomorrowsVintage.svg";
import CloseButton from "../../svgs/CloseButton";
import MenuButton from "../../svgs/MenuButton";

function Header({ isOpen, setIsOpen, isSticky }) {
  const goToAnchor = (anchorName) => {
    window.location.href = anchorName;
    setIsOpen(false);
  };

  return (
    <div className={"header " + (isSticky ? "sticky" : "notsticky")}>
      <div className="headerContainer">
        <MenuButton setIsOpen={setIsOpen} />
        <div className="tvLogo">
          <img src={tomorrowsVintageSVG} alt="Tomorrow's Vintage" />
        </div>
      </div>
      {isOpen && (
        <div className="nav">
          <CloseButton setIsOpen={setIsOpen} />
          <div className="navContainer">
            <button
              onClick={() => {
                goToAnchor("#mission");
              }}
            >
              Our Mission
            </button>
            <button
              onClick={() => {
                goToAnchor("#help");
              }}
            >
              How do I help protect Australian wine?
            </button>
            <button
              onClick={() => {
                goToAnchor("#join");
              }}
            >
              Take Action
            </button>
            <button
              onClick={() => {
                goToAnchor("#about");
              }}
            >
              About Ampersand Estates
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
