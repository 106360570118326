const CloseButton = ({ setIsOpen }) => {
  return (
    <div
      className="closeButton"
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74">
        <g fill="none" stroke="#c1aa6a" strokeWidth="3">
          <g>
            <circle cx="37" cy="37" r="35.5" />
          </g>
          <path strokeLinecap="round" d="M49 25 25 49" />
          <path strokeLinecap="round" d="M49 49 25 25" />
        </g>
      </svg>
    </div>
  );
};
export default CloseButton;
