import "./Lightbox.css";
import scenery from '../../assets/Australian_Scenery.mp4';
import PinSVG from "../../assets/pin.svg";
import AmpersandSVG from "../../assets/ampersand.svg";
import bgTextureSVG from "../../assets/texture2.svg";

function Lightbox({
  id,
  title,
  image,
  buttonText,
  hasVideo,
  hasCardBackground,
  isOpen,
  setIsOpen,
  children,
}) {
  return (
    <div className="lightbox" id={id}>
      {hasVideo ? <video className='backgroundVideo' autoPlay loop muted playsInline>
        <source src={scenery} type='video/mp4' />
      </video>  
      : <div
        className={
          "background backgroundImage " +
          (isOpen ? "blur" : "")
        }
      />}
      <div
        className={
          "box " + (hasCardBackground ? "bg " : "") + (isOpen ? "hide" : "show")
        }
      >
        {hasCardBackground && (
          <img className="bgTexture" src={bgTextureSVG} alt="bg texture" />
        )}
        <h2 className="title">{title}</h2>
        <div className="lightboxSVGContainer">
          <img
            className="svg"
            src={image === "PinSVG" ? PinSVG : AmpersandSVG}
            alt={image}
          />
        </div>
        <button
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {buttonText}
        </button>
      </div>
      {children}
    </div>
  );
}

export default Lightbox;
