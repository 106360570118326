import Lightbox from "../lightbox/Lightbox";
import Link from "../link/Link";
import "./About.css";
import CloseButton from "../../svgs/CloseButton";
import { LazyLoadImage } from "react-lazy-load-image-component";

function About({ id, isOpen, setIsOpen, sectionRef }) {
  return (
    <div className="about">
      <div ref={sectionRef} className="contentWrapper">
        <h2 className="join">
          Join our cause.
          <br /> If not for the planet, do it for the wine.
        </h2>
        <div className="linkContainer">
          <Link
            text="Want to sign a Conservation Agreement?"
            linkText="Start the process"
            href="https://www.hsi.org.au/wltjoin"
          />
          <Link
            text="Know an Australian landowner who might be interested?"
            linkText="Share with a friend"
            href="mailto:?subject=Help%20me%20save%20the%20future%20of%20Australian%20wine&body=I%E2%80%99m%20sending%20you%20this%20because%20as%20an%20Australian%20landowner%2C%20you%20can%20help%20prevent%20a%20future%20where%20our%20country%E2%80%99s%20wine%20goes%20extinct.
            %0D%0A%0D%0AClimate%20change%20is%20drastically%20shrinking%20our%20wine-growing%20regions.%20And%20soon%2C%20Australian%20soil%20won%E2%80%99t%20be%20able%20to%20grow%20the%20grapes%20used%20to%20make%20Shiraz%2C%20Pinot%20Noir%2C%20Sauvignon%20Blanc%2C%20and%20more.
            %0D%0A%0D%0ABut%20as%20a%20landowner%2C%20you%20can%20help%20prevent%20this%20future%20by%20signing%20a%20Conservation%20Agreement.%20It%E2%80%99s%20the%20first%20step%20in%20safeguarding%20your%20land%20against%20environmentally%20harmful%20activities%20to%20protect%20Australia%E2%80%99s%20vital%20ecosystems%2C%20including%20the%20ones%20that%20grow%20our%20favourite%20grapes.%0D%0A%0D%0AGo%20to%20https://www.tomorrowsvintage.com.au%20to%20learn%20more. 
            %0D%0A%0D%0ALet%E2%80%99s%20protect%20the%20future%20of%20wine.%20And%20the%20next%20time%20I%20see%20you%2C%20let%E2%80%99s%20raise%20a%20glass%20to%20it%2C%20too."
          />
          <Link
            text="Want to make a difference but don’t own land?"
            linkText="Donate to the Wildlife Land Trust"
            href="https://www.hsi.org.au/wltdonate"
          />
        </div>
      </div>
      <Lightbox
        hasCardBackground={true}
        title="Meet the winery behind Tomorrow’s Vintage."
        image="AmpersandSVG"
        buttonText="Who are we?"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={id}
      >
        <div
          className={
            "box " + (isOpen ? "show " : "hide") + (isOpen ? "bgIsOpen" : "")
          }
        >
          <div className="wrapper">
            <h2>About Ampersand Estates</h2>
            <p>
            Located in the heart of Western Australia’s Pemberton region, 
            Ampersand Estates is an independent, boutique winery with a 
            passion for making award-winning wines, matched only by our 
            passion for protecting the lands they grow on. That’s why we’re 
            committed to using sustainable practices in all aspects of our 
            operations: including dryland farming to reduce our water consumption, 
            conserve energy, and maintain healthy soil. But protecting our own 
            land isn’t enough. We all need to take meaningful action. So, we 
            invite our fellow winemakers, and all Australians to join our effort 
            to fight climate change, and to ensure we have a future worth drinking to.
            </p>
            <button onClick={(e) => {
                e.preventDefault();
                window.open("https://ampersandestates.com.au/","_blank");
            }}>
              Learn more
            </button>
            <div className="gallery">
              <div className="galleryRow1">
                <LazyLoadImage src={require("../../assets/aeWinery1.png")} alt="winery" />
                <LazyLoadImage src={require("../../assets/aeWinery2.png")} alt="winery" />
              </div>
              <div className="galleryRow2">
                <LazyLoadImage src={require("../../assets/aeWinery3.png")} alt="winery" />
              </div>
            </div>
            <CloseButton setIsOpen={setIsOpen} />
          </div>
        </div>
      </Lightbox>
    </div>
  );
}

export default About;
