import "./Link.css";
import ArrowSVG from "../../assets/arrow.svg";

function Link({ text, linkText, href }) {
  return (
    <div className="link">
      <hr className="horizontalLine" />
      <div className="linkItemContainer">
        <p>{text}</p>
        <p className="href">
          <a href={href} target="_blank" rel="noreferrer">{linkText}</a> <img src={ArrowSVG} alt="arrow" />
        </p>
      </div>
    </div>
  );
}

export default Link;
