const MenuButton = ({ setIsOpen }) => {
  return (
    <div
      className="menuButton"
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="23.5">
        <g fill="none" stroke="#000" strokeWidth="1.5">
          <path d="M64 .75H0" data-name="Line 1" />
          <path d="M45 11.75H0" data-name="Line 2" />
          <path d="M55 22.75H0" data-name="Line 3" />
        </g>
      </svg>
    </div>
  );
};
export default MenuButton;
