import "./Footer.css";
import FooterSVG from "../../assets/footerTV.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="footerWrapper">
        <img className="footerSvg" alt="footer logo" src={FooterSVG} />
        <div className="footerText">
          <div className="footerTextLeft">
            <p>
              *The percentage of viable remaining wine-growing land and the
              expected rate of decline are estimated based on current climate
              models and expert predictions from various sources, including the
              following articles:
            </p>
            <div className="footerLinks">
              <a target="_blank" href="https://news.climate.columbia.edu/2020/01/27/wine-regions-shrink-climate-change/" rel="noreferrer">Columbia University</a>
              <a target="_blank" href="https://www.reuters.com/business/sustainable-business/hard-hit-by-climate-change-winemakers-turn-sustainability-ride-storms-2022-09-14/" rel="noreferrer">Reuters</a>
              <a target="_blank" href="https://www.usatoday.com/story/news/nation/2020/01/27/wine-supply-threatened-climate-change-new-study-says/4590653002/" rel="noreferrer">USA Today</a>
            </div>
            <p>
              2023 Tomorrow’s Vintage. <br /> All rights reserved.
            </p>
          </div>
          <div className="footerTextRight">
            <p>
              Created in partnership with <a target="_blank" href="https://ampersandestates.com.au/" rel="noreferrer">Ampersand Estates</a> and the <a target="_blank" href="https://www.hsi.org.au/wltlearnmore" rel="noreferrer">Wildlife Land Trust</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
