import "./App.css";

import React, { useRef, useEffect, useState, useCallback } from "react";
import { gsap } from "gsap";
import "./App.css";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lightbox from "./components/lightbox/Lightbox";
import About from "./components/about/About";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";

import bgTextureSVG from "./assets/texture1.svg";
import CloseButton from "./svgs/CloseButton";

import bulkMapImages from "./MapImages";
import bulkWineImages from "./WineImages";

gsap.registerPlugin(ScrollTrigger);

function App() {
  const sectionRefs = useRef([]);
  const rootContainerRef = useRef();

  const s1TextRef = useRef();

  const blockOne = useRef();
  const blockTwo = useRef();
  const blockThree = useRef();

  const animRef = useRef();

  const cursorAnimRef = useRef();

  const sectionFourRef = useRef();

  const [startTimeline, setStartTimeline] = useState(false);

  const addToSectionRefs = (el) => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el);
    }
  };

  const [isProtectOpen, setIsProtectOpen] = useState(false);
  const [isMissionOpen, setIsMissionOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isNavSticky, setIsNavSticky] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);

  const [mapIndex, setMapIndex] = useState(0);
  const [wineIndex, setWineIndex] = useState(0);

  const isMobile = useRef(window.innerWidth <= 768);

  const wine = useRef({ frame: 0 });

  const wframeStop0 = 48;
  const wframeStop1 = 119;
  const wframeStop2 = 178;
  const wframeStop3 = 213;

  useEffect(() => {
    let tl = gsap.timeline({ repeat: -1 });

    tl.fromTo(
      cursorAnimRef.current,
      { yPercent: -100 },
      { yPercent: 100, duration: 1.1 }
    );
  }, []);

  const updateMap = useCallback((i) => {
    setMapIndex(i);
  }, []);

  const updateWine = useCallback(() => {
    setWineIndex(wine.current.frame);

    if (wine.current.frame < 210) {
      setIsNavSticky(true);
    } else {
      setIsNavSticky(false);
    }

    if (wine.current.frame < 20) {
      updateMap(0);
    } else if (wine.current.frame >= 20 && wine.current.frame < 30) {
      updateMap(1);
    } else if (wine.current.frame >= 30 && wine.current.frame < 42) {
      updateMap(2);
      setHasScrolled(true);
    } else if (wine.current.frame >= 42 && wine.current.frame < 165) {
      updateMap(3);
    } else if (wine.current.frame >= 165 && wine.current.frame < 210) {
      updateMap(4);
    } else if (wine.current.frame >= 210) {
      updateMap(5);
    }
  }, [updateMap]);

  useEffect(() => {
    if (rootContainerRef.current) {
      setStartTimeline(true);
    }
  }, []);

  useEffect(() => {
    if (startTimeline) {
      sectionRefs.current.forEach((section, i) => {
        let tl = gsap.timeline();

        if (section.dataset.pin === "2") {
          const textChildren = Array.from(animRef.current.children);
          textChildren.forEach((e, i) => {
            ScrollTrigger.create({
              trigger: e,
              start: "top 80%",
              end: "bottom 50%",
              onEnter: () => gsap.to(e, { y: 0, opacity: 1, duration: 0.4 }),
              onEnterBack: () =>
                gsap.to(e, { y: 0, opacity: 1, duration: 0.4 }),
              onLeave: () => gsap.to(e, { y: -100, opacity: 0, duration: 0.4 }),
              onLeaveBack: () =>
                gsap.to(e, { y: 100, opacity: 0, duration: 0.4 }),
            });
          });
        }

        if (section.dataset.pin === "1") {
          tl.to(
            wine.current,
            {
              frame: wframeStop0 - 1,
              snap: "frame",
              onUpdate: updateWine,
            },
            0
          );

          tl.to(s1TextRef.current, { autoAlpha: 0, y: -100, display: "none" });
          tl.fromTo(
            blockOne.current,
            { autoAlpha: 0, xPercent: isMobile.current ? -50 : 0 },
            { autoAlpha: 1, yPercent: isMobile.current ? -45 : -50 },
            "to2040"
          );
          tl.to(
            wine.current,
            {
              frame: wframeStop1 - 1,
              snap: "frame",
              onUpdate: updateWine,
            },
            "to2040"
          );

          tl.to(
            blockOne.current,
            { autoAlpha: 0, yPercent: -100, display: "none" },
            "to2040+=0.5"
          );
          tl.fromTo(
            blockTwo.current,
            { autoAlpha: 0, xPercent: isMobile.current ? -50 : 0 },
            { autoAlpha: 1, yPercent: isMobile.current ? -45 : -50 },
            "to2040+=0.8"
          );
          tl.to(
            wine.current,
            {
              frame: wframeStop2 - 1,
              snap: "frame",
              onUpdate: updateWine,
            },
            "to2040+=0.8"
          );

          tl.to(blockTwo.current, { autoAlpha: 0, yPercent: -100 }, "to2100");
          tl.fromTo(
            blockThree.current,
            { autoAlpha: 0, xPercent: isMobile.current ? -50 : 0 },
            { autoAlpha: 1, yPercent: isMobile.current ? -45 : -50 },
            "to2100+=0.3"
          );
          tl.to(
            wine.current,
            {
              frame: wframeStop3 - 1,
              snap: "frame",
              onUpdate: updateWine,
            },
            "to2100+=0.3"
          );

          ScrollTrigger.create({
            animation: tl,
            id: "mask-" + i,
            pin: section.dataset.pin === "1",
            trigger: section,
            scrub: true,
            anticipatePin: 1,
          });
        }

        if (section.dataset.pin === "4") {
          const sectionFourChildren = Array.from(
            sectionFourRef.current.children
          );
          sectionFourChildren.forEach((e, i) => {
            ScrollTrigger.create({
              trigger: e,
              start: "top 70%",
              end: "bottom 50%",
              onEnter: () => gsap.to(e, { y: 0, opacity: 1, duration: 0.4 }),
              onEnterBack: () =>
                gsap.to(e, { y: 0, opacity: 1, duration: 0.4 }),
              onLeave: () => gsap.to(e, { y: -100, opacity: 0, duration: 0.4 }),
              onLeaveBack: () =>
                gsap.to(e, { y: 100, opacity: 0, duration: 0.4 }),
            });
          });
        }
      });
    }
  }, [startTimeline, updateWine, wframeStop3]);

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isNavOpen]);

  return (
    <div className="rootContainer" ref={rootContainerRef}>
      <Header
        isOpen={isNavOpen}
        isSticky={isNavSticky}
        setIsOpen={setIsNavOpen}
      />
      <section className="sectionOne" data-pin="1" ref={addToSectionRefs}>
        <div className="textContainer">
          <div ref={s1TextRef}>
            <div className="startContainer">
              <h1>
                See how climate change is threatening the future of Australian
                wine.
              </h1>
            </div>
          </div>
          <div className="statisticsContainer" ref={blockOne}>
            <h2 className="year">2040</h2>
            <div className="eyebrowContainer">
              <h3 className="percent">
                Only 86% <span className="asterik">*</span>
              </h3>
              <div className="infoContainer">
                <h4>
                  of wine-growing regions will still be useable by this year
                </h4>
              </div>
            </div>
          </div>
          <div className="statisticsContainer" ref={blockTwo}>
            <h2 className="year">2080</h2>
            <div className="eyebrowContainer">
              <h3 className="percent">
                Only 56% <span className="asterik">*</span>
              </h3>
              <div className="infoContainer">
                <h4>
                  will still be viable a <br />
                  mere 40 years later
                </h4>
              </div>
            </div>
          </div>
          <div className="statisticsContainer" ref={blockThree}>
            <h2 className="year">2100</h2>
            <div className="eyebrowContainer">
              <h3 className="percent">
                Only 44% <span className="asterik">*</span>
              </h3>
              <div className="infoContainer">
                <h4>
                  will still exist by 2100, <br />
                  unless we take action
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="cursorWrapper">
          <div className={"cursor " + (hasScrolled ? "hide" : "")}>
            <div className="cursorLine">
              <div className="innerLine" ref={cursorAnimRef} />
            </div>
          </div>
        </div>
        {bulkMapImages.map((img, i) => (
          <img
            key={i}
            src={img}
            alt="map frame"
            className={mapIndex === i ? "map" : "frameHide"}
          />
        ))}
        {bulkWineImages.map((img, i) => (
          <img
            key={i}
            src={img}
            alt="wine frame"
            className={
              wineIndex === i || wineIndex >= 213 ? "wine" : "frameHide"
            }
          />
        ))}
      </section>

      <section
        className="sectionTwo"
        id="mission"
        data-pin="2"
        ref={addToSectionRefs}
      >
        <div className="missionContainer" ref={animRef}>
          <h2 className="textOne">
            ​We’re on a mission to save tomorrow’s vintages.
          </h2>
          <h2 className="textTwo">
            Because life without Australian wine is one we can’t imagine
            enjoying.
          </h2>
          <p className="textThree">
            Climate change is drastically shrinking our wine-growing regions.
            Soon, Australia won’t be able to grow the grapes used to make
            Shiraz, Pinot Noir, Sauvignon Blanc, and your other favourite
            varietals. Fortunately, the glass is still half full.
          </p>
        </div>
        <img
          className="missionTexture"
          src={bgTextureSVG}
          alt="bg texture"
        />
      </section>

      <section className="sectionThree" id="help">
        <Lightbox
          hasVideo={true}
          title="Protect your land to help protect Australian wine."
          image="PinSVG"
          buttonText="How do I do that?"
          isOpen={isProtectOpen}
          setIsOpen={setIsProtectOpen}
        >
          <div className={"box " + (isProtectOpen ? "show" : "hide")}>
            <div className="wrapper">
              <h2>Sign a Conservation Agreement for your land.</h2>
              <p>
                A Conservation Agreement is made between you (a private
                landowner) and an organization like the Wildlife Land Trust.
                It’s the first step in protecting your land’s ecological
                integrity forever, even if you sell or pass on your property.
                Once set up, the Conservation Agreement helps prevent any
                environmentally harmful activities from taking place on your
                land, and gives you access to professional support and
                information.
              </p>
              <p>
                This agreement doesn’t affect your land ownership and costs
                nothing to implement. Think of it as becoming a steward of your
                land for the rest of time.
              </p>
              <h2>How will this help?</h2>
              <p>
                When environmentally harmful activities take place, they affect
                much more than just the piece of land they happen on. When
                carbon is released into the atmosphere, or chemicals leech into
                the surrounding environment, entire ecosystems suffer, and the
                results can be long-lasting and widespread. By preventing these
                activities and working to conserve your land, you’ll help
                protect Australia’s vital ecosystems, including the ones that
                grow our favourite grapes.
              </p>
              <CloseButton setIsOpen={setIsProtectOpen} />
            </div>
          </div>
        </Lightbox>
      </section>
      <section
        className="sectionFour"
        id="join"
        data-pin="4"
        ref={addToSectionRefs}
      >
        <About
          id="about"
          isOpen={isMissionOpen}
          setIsOpen={setIsMissionOpen}
          sectionRef={sectionFourRef}
        />
      </section>
      <Footer />
    </div>
  );
}

export default App;
