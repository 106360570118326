const bulkWineImages = [
    require(`./assets/landing/wine/wine-1.png`),
    require(`./assets/landing/wine/wine-2.png`),
    require(`./assets/landing/wine/wine-3.png`),
    require(`./assets/landing/wine/wine-4.png`),
    require(`./assets/landing/wine/wine-5.png`),
    require(`./assets/landing/wine/wine-6.png`),
    require(`./assets/landing/wine/wine-7.png`),
    require(`./assets/landing/wine/wine-8.png`),
    require(`./assets/landing/wine/wine-9.png`),

    require(`./assets/landing/wine/wine-10.png`),
    require(`./assets/landing/wine/wine-11.png`),
    require(`./assets/landing/wine/wine-12.png`),
    require(`./assets/landing/wine/wine-13.png`),
    require(`./assets/landing/wine/wine-14.png`),
    require(`./assets/landing/wine/wine-15.png`),
    require(`./assets/landing/wine/wine-16.png`),
    require(`./assets/landing/wine/wine-17.png`),
    require(`./assets/landing/wine/wine-18.png`),
    require(`./assets/landing/wine/wine-19.png`),

    require(`./assets/landing/wine/wine-20.png`),
    require(`./assets/landing/wine/wine-21.png`),
    require(`./assets/landing/wine/wine-22.png`),
    require(`./assets/landing/wine/wine-23.png`),
    require(`./assets/landing/wine/wine-24.png`),
    require(`./assets/landing/wine/wine-25.png`),
    require(`./assets/landing/wine/wine-26.png`),
    require(`./assets/landing/wine/wine-27.png`),
    require(`./assets/landing/wine/wine-28.png`),
    require(`./assets/landing/wine/wine-29.png`),

    require(`./assets/landing/wine/wine-30.png`),
    require(`./assets/landing/wine/wine-31.png`),
    require(`./assets/landing/wine/wine-32.png`),
    require(`./assets/landing/wine/wine-33.png`),
    require(`./assets/landing/wine/wine-34.png`),
    require(`./assets/landing/wine/wine-35.png`),
    require(`./assets/landing/wine/wine-36.png`),
    require(`./assets/landing/wine/wine-37.png`),
    require(`./assets/landing/wine/wine-38.png`),
    require(`./assets/landing/wine/wine-39.png`),

    require(`./assets/landing/wine/wine-40.png`),
    require(`./assets/landing/wine/wine-41.png`),
    require(`./assets/landing/wine/wine-42.png`),
    require(`./assets/landing/wine/wine-43.png`),
    require(`./assets/landing/wine/wine-44.png`),
    require(`./assets/landing/wine/wine-45.png`),
    require(`./assets/landing/wine/wine-46.png`),
    require(`./assets/landing/wine/wine-47.png`),
    require(`./assets/landing/wine/wine-48.png`),
    require(`./assets/landing/wine/wine-49.png`),

    require(`./assets/landing/wine/wine-50.png`),
    require(`./assets/landing/wine/wine-51.png`),
    require(`./assets/landing/wine/wine-52.png`),
    require(`./assets/landing/wine/wine-53.png`),
    require(`./assets/landing/wine/wine-54.png`),
    require(`./assets/landing/wine/wine-55.png`),
    require(`./assets/landing/wine/wine-56.png`),
    require(`./assets/landing/wine/wine-57.png`),
    require(`./assets/landing/wine/wine-58.png`),
    require(`./assets/landing/wine/wine-59.png`),

    require(`./assets/landing/wine/wine-60.png`),
    require(`./assets/landing/wine/wine-61.png`),
    require(`./assets/landing/wine/wine-62.png`),
    require(`./assets/landing/wine/wine-63.png`),
    require(`./assets/landing/wine/wine-64.png`),
    require(`./assets/landing/wine/wine-65.png`),
    require(`./assets/landing/wine/wine-66.png`),
    require(`./assets/landing/wine/wine-67.png`),
    require(`./assets/landing/wine/wine-68.png`),
    require(`./assets/landing/wine/wine-69.png`),

    require(`./assets/landing/wine/wine-70.png`),
    require(`./assets/landing/wine/wine-71.png`),
    require(`./assets/landing/wine/wine-72.png`),
    require(`./assets/landing/wine/wine-73.png`),
    require(`./assets/landing/wine/wine-74.png`),
    require(`./assets/landing/wine/wine-75.png`),
    require(`./assets/landing/wine/wine-76.png`),
    require(`./assets/landing/wine/wine-77.png`),
    require(`./assets/landing/wine/wine-78.png`),
    require(`./assets/landing/wine/wine-79.png`),

    require(`./assets/landing/wine/wine-80.png`),
    require(`./assets/landing/wine/wine-81.png`),
    require(`./assets/landing/wine/wine-82.png`),
    require(`./assets/landing/wine/wine-83.png`),
    require(`./assets/landing/wine/wine-84.png`),
    require(`./assets/landing/wine/wine-85.png`),
    require(`./assets/landing/wine/wine-86.png`),
    require(`./assets/landing/wine/wine-87.png`),
    require(`./assets/landing/wine/wine-88.png`),
    require(`./assets/landing/wine/wine-89.png`),

    require(`./assets/landing/wine/wine-90.png`),
    require(`./assets/landing/wine/wine-91.png`),
    require(`./assets/landing/wine/wine-92.png`),
    require(`./assets/landing/wine/wine-93.png`),
    require(`./assets/landing/wine/wine-94.png`),
    require(`./assets/landing/wine/wine-95.png`),
    require(`./assets/landing/wine/wine-96.png`),
    require(`./assets/landing/wine/wine-97.png`),
    require(`./assets/landing/wine/wine-98.png`),
    require(`./assets/landing/wine/wine-99.png`),

    require(`./assets/landing/wine/wine-100.png`),
    require(`./assets/landing/wine/wine-101.png`),
    require(`./assets/landing/wine/wine-102.png`),
    require(`./assets/landing/wine/wine-103.png`),
    require(`./assets/landing/wine/wine-104.png`),
    require(`./assets/landing/wine/wine-105.png`),
    require(`./assets/landing/wine/wine-106.png`),
    require(`./assets/landing/wine/wine-107.png`),
    require(`./assets/landing/wine/wine-108.png`),
    require(`./assets/landing/wine/wine-109.png`),

    require(`./assets/landing/wine/wine-110.png`),
    require(`./assets/landing/wine/wine-111.png`),
    require(`./assets/landing/wine/wine-112.png`),
    require(`./assets/landing/wine/wine-113.png`),
    require(`./assets/landing/wine/wine-114.png`),
    require(`./assets/landing/wine/wine-115.png`),
    require(`./assets/landing/wine/wine-116.png`),
    require(`./assets/landing/wine/wine-117.png`),
    require(`./assets/landing/wine/wine-118.png`),
    require(`./assets/landing/wine/wine-119.png`),

    require(`./assets/landing/wine/wine-120.png`),
    require(`./assets/landing/wine/wine-121.png`),
    require(`./assets/landing/wine/wine-122.png`),
    require(`./assets/landing/wine/wine-123.png`),
    require(`./assets/landing/wine/wine-124.png`),
    require(`./assets/landing/wine/wine-125.png`),
    require(`./assets/landing/wine/wine-126.png`),
    require(`./assets/landing/wine/wine-127.png`),
    require(`./assets/landing/wine/wine-128.png`),
    require(`./assets/landing/wine/wine-129.png`),

    require(`./assets/landing/wine/wine-130.png`),
    require(`./assets/landing/wine/wine-131.png`),
    require(`./assets/landing/wine/wine-132.png`),
    require(`./assets/landing/wine/wine-133.png`),
    require(`./assets/landing/wine/wine-134.png`),
    require(`./assets/landing/wine/wine-135.png`),
    require(`./assets/landing/wine/wine-136.png`),
    require(`./assets/landing/wine/wine-137.png`),
    require(`./assets/landing/wine/wine-138.png`),
    require(`./assets/landing/wine/wine-139.png`),

    require(`./assets/landing/wine/wine-140.png`),
    require(`./assets/landing/wine/wine-141.png`),
    require(`./assets/landing/wine/wine-142.png`),
    require(`./assets/landing/wine/wine-143.png`),
    require(`./assets/landing/wine/wine-144.png`),
    require(`./assets/landing/wine/wine-145.png`),
    require(`./assets/landing/wine/wine-146.png`),
    require(`./assets/landing/wine/wine-147.png`),
    require(`./assets/landing/wine/wine-148.png`),
    require(`./assets/landing/wine/wine-149.png`),

    require(`./assets/landing/wine/wine-150.png`),
    require(`./assets/landing/wine/wine-151.png`),
    require(`./assets/landing/wine/wine-152.png`),
    require(`./assets/landing/wine/wine-153.png`),
    require(`./assets/landing/wine/wine-154.png`),
    require(`./assets/landing/wine/wine-155.png`),
    require(`./assets/landing/wine/wine-156.png`),
    require(`./assets/landing/wine/wine-157.png`),
    require(`./assets/landing/wine/wine-158.png`),
    require(`./assets/landing/wine/wine-159.png`),

    require(`./assets/landing/wine/wine-160.png`),
    require(`./assets/landing/wine/wine-161.png`),
    require(`./assets/landing/wine/wine-162.png`),
    require(`./assets/landing/wine/wine-163.png`),
    require(`./assets/landing/wine/wine-164.png`),
    require(`./assets/landing/wine/wine-165.png`),
    require(`./assets/landing/wine/wine-166.png`),
    require(`./assets/landing/wine/wine-167.png`),
    require(`./assets/landing/wine/wine-168.png`),
    require(`./assets/landing/wine/wine-169.png`),

    require(`./assets/landing/wine/wine-170.png`),
    require(`./assets/landing/wine/wine-171.png`),
    require(`./assets/landing/wine/wine-172.png`),
    require(`./assets/landing/wine/wine-173.png`),
    require(`./assets/landing/wine/wine-174.png`),
    require(`./assets/landing/wine/wine-175.png`),
    require(`./assets/landing/wine/wine-176.png`),
    require(`./assets/landing/wine/wine-177.png`),
    require(`./assets/landing/wine/wine-178.png`),
    require(`./assets/landing/wine/wine-179.png`),

    require(`./assets/landing/wine/wine-180.png`),
    require(`./assets/landing/wine/wine-181.png`),
    require(`./assets/landing/wine/wine-182.png`),
    require(`./assets/landing/wine/wine-183.png`),
    require(`./assets/landing/wine/wine-184.png`),
    require(`./assets/landing/wine/wine-185.png`),
    require(`./assets/landing/wine/wine-186.png`),
    require(`./assets/landing/wine/wine-187.png`),
    require(`./assets/landing/wine/wine-188.png`),
    require(`./assets/landing/wine/wine-189.png`),

    require(`./assets/landing/wine/wine-190.png`),
    require(`./assets/landing/wine/wine-191.png`),
    require(`./assets/landing/wine/wine-192.png`),
    require(`./assets/landing/wine/wine-193.png`),
    require(`./assets/landing/wine/wine-194.png`),
    require(`./assets/landing/wine/wine-195.png`),
    require(`./assets/landing/wine/wine-196.png`),
    require(`./assets/landing/wine/wine-197.png`),
    require(`./assets/landing/wine/wine-198.png`),
    require(`./assets/landing/wine/wine-199.png`),
    
    require(`./assets/landing/wine/wine-200.png`),
    require(`./assets/landing/wine/wine-201.png`),
    require(`./assets/landing/wine/wine-202.png`),
    require(`./assets/landing/wine/wine-203.png`),
    require(`./assets/landing/wine/wine-204.png`),
    require(`./assets/landing/wine/wine-205.png`),
    require(`./assets/landing/wine/wine-206.png`),
    require(`./assets/landing/wine/wine-207.png`),
    require(`./assets/landing/wine/wine-208.png`),
    require(`./assets/landing/wine/wine-209.png`),
    require(`./assets/landing/wine/wine-210.png`),
    require(`./assets/landing/wine/wine-211.png`),
    require(`./assets/landing/wine/wine-212.png`),
    require(`./assets/landing/wine/wine-213.png`),
  ];
  
  export default bulkWineImages;